import { GetterTree } from 'vuex';
import { CustomizationState, ThemeColors } from '@customization/models/ColorConfig';
import { RootState } from '@customization/types/types';

const getters: GetterTree<CustomizationState, RootState> = {
  theme: (state) => state.theme,
  themeColors: (state): ThemeColors => state.theme.colors,
};

export default getters;
