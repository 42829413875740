import _ from "lodash"
import i18n from "@/plugins/i18n";
import AbrapaIntegrationsService from "@integrations/abrapa/services/AbrapaIntegrationsService";
import LanguageService from "@/services/LanguajeService";

export default {
  async CREATE_PRODUCT({ commit }, payload) {
    try {
      const response = await AbrapaIntegrationsService.createProduct(payload);
      this._vm.$toast.success(i18n.t('abrapa.notifications.saved', { integration: i18n.t('abrapa.fields.product') }), {
        queueable: true
      });
      return response;
    } catch (error) {
      this._vm.$toast.error(i18n.t('abrapa.notifications.failed', { integration: i18n.t('abrapa.fields.order') }), {
        queueable: true,
      });
      throw error;
    }
  },

  async CREATE_ORDER({ commit }, [payload, isBulk = false]) {
    try {
      const response = await AbrapaIntegrationsService.createOrder(payload);

      if (!isBulk)
        this._vm.$toast.success(i18n.t('abrapa.notifications.saved', { integration: i18n.t('abrapa.fields.product') }), {
          queueable: true
        });
      return response;
    } catch (error) {
      if (!isBulk)
        this._vm.$toast.error(i18n.t('abrapa.notifications.failed', { integration: i18n.t('abrapa.fields.order') }), {
          queueable: true,
        });
      throw error;
    }
  },

  async FETCH_STORES({ commit }) {
    try {
      const { data } = await AbrapaIntegrationsService.fetchStores();
      return data;
    } catch (error) {
      throw error;
    }
  },

  async FETCH_UNITS({ commit }) {
    try {
      const { data } = await AbrapaIntegrationsService.fetchUnits();
      return _.map(data, d => ({
        ...d,
        name: LanguageService.getKey3(d.languageKey)
      }));
    } catch (error) {
      throw error;
    }
  },
};
