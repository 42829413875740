import { render, staticRenderFns } from "./BrainAgNotification.vue?vue&type=template&id=3dcb9218&scoped=true"
import script from "./BrainAgNotification.vue?vue&type=script&lang=js"
export * from "./BrainAgNotification.vue?vue&type=script&lang=js"
import style0 from "./BrainAgNotification.vue?vue&type=style&index=0&id=3dcb9218&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dcb9218",
  null
  
)

export default component.exports