
  import { Component, Vue } from 'vue-property-decorator';
  import { mapActions, mapGetters } from 'vuex';
  import { ThemeColors } from '@customization/models/ColorConfig';

  @Component({
    computed: {
      ...mapGetters('customization', ['theme', 'themeColors']),
    },
    methods: {
      ...mapActions('customization', [
        'UPDATE_THEME_COLORS',
        'SAVE_CONFIGURATION',
      ]),
    },
  })
  export default class DrawerCustomizer extends Vue {
    // Drawer state
    isDrawerOpen: boolean = false;

    // Initialize colors based on themeColors
    originalColors: ThemeColors = { ...this.themeColors };
    localColors: ThemeColors = { ...this.themeColors };
    previewColors: ThemeColors = { ...this.themeColors };

    // Vuex Getter for themeColors
    get themeColors(): ThemeColors {
      return this.$store.getters['customization/themeColors'];
    }

    // Vuex Getter for theme
    get theme() {
      return this.$store.getters['customization/theme'];
    }

    // Filter only the colors that can be modified
    get filteredColors(): Partial<ThemeColors> {
      const modifiableKeys = ['accent', 'primary', 'secondary', 'surface'];
      const filteredColors: Partial<ThemeColors> = {};

      modifiableKeys.forEach((key) => {
        if (key in this.localColors) {
          filteredColors[key] = this.localColors[key];
        }
      });

      return filteredColors;
    }

    // State to track which menu is open
    menus: { [key: string]: boolean } = {};

    // State to control hover effect on color block
    hoverColor: string | null = null;

    // Method to toggle the drawer
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;

      if (this.isDrawerOpen) {
        this.localColors = { ...this.themeColors };
      } else {
        this.$store.commit('customization/UPDATE_THEME_COLORS', this.originalColors);
        this.clearColorStates();
      }
    }

    // Method to cancel color editing in the drawer
    cancelColorEdit(key: string) {
      this.localColors[key] = this.originalColors[key];
      this.menus[key] = false;
    }

    // Method to apply a preview of the selected colors in the drawer
    applyPreview() {
      this.previewColors = { ...this.themeColors };
      this.$store.commit('customization/UPDATE_THEME_COLORS', this.localColors);
    }

    // Method to restore the original colors to the view after previewing
    restoreOriginalColors() {
      this.localColors = { ...this.originalColors };
      this.$store.commit('customization/UPDATE_THEME_COLORS', this.originalColors);
    }

    // Method to save the selected colors permanently
    async saveColors() {
      this.originalColors = { ...this.localColors };
      await this.$store.dispatch('customization/UPDATE_THEME_COLORS', this.localColors);
      await this.$store.dispatch('customization/SAVE_CONFIGURATION');
      this.toggleDrawer();
      this.clearColorStates();
    }

    // Method to clear the state of local and preview colors
    clearColorStates() {
      this.localColors = { ...this.themeColors };
      this.previewColors = { ...this.themeColors };
    }

    // Hook for when the component is mounted
    async mounted() {
      await this.$store.dispatch('customization/LOAD_CONFIGURATION');
      this.originalColors = { ...this.themeColors };
      this.localColors = { ...this.themeColors };
      this.previewColors = { ...this.themeColors };
    }
  }
