<template>
  <fragment>
    <template>
      <div class="text-left">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="110"
            offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mt-2 ml-3"
                   v-bind="attrs"
                   v-on="on"
                   fab
                   x-small
                   elevation="0"
                   tile
            >
              <v-icon small color='black'>mdi-palette
              </v-icon>
            </v-btn>
          </template>

          <v-card  style="overflow: hidden">
            <v-card-title class=" py-2 font-weight-regular secondary t-bw-secondary--text">{{ $t('map.titles.locationColor') }}</v-card-title>
            <v-divider></v-divider>
            <div>
              <v-color-picker
                dot-size="25"
                swatches-max-height="200"
                v-model="color"
                :value="color"
                ref="colorPicker"
                class="ma-2 elevation-0 v-alert--outlined"
                :swatches="swatches"
                show-swatches
            ></v-color-picker>
            </div>

            <v-card-actions class="pa-6">
              <v-row
                  align="center"
                  justify="space-around"

              >
                <v-btn
                  small elevation="0" color="primary" class="t-bw-primary--text"
                  @click="switchColor"

                >
                  {{$t('general.buttons.confirm')}}
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </template>
  </fragment>
</template>
<script>
export default {
  name: "MapColorPicker",
  inject: ["mapbox", "map", "actions"],
  props: [
  ],
  data() {
    return {
      menu: false,
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055'],
      ],
    };
  },
  computed: {
    color: {
      get() {
        return this.$store.getters['general/color']
      },
      set(val) {
        this.$store.commit('general/SET_COLOR', val)
      },
    },
  },


  methods: {
    switchColor() {
      this.menu = false;
      this.$emit('colorChange')

    }
  }
};
</script>

<style scoped>
</style>