import * as types from './mutations-type'
import router from '@/router'

export default {
    [types.SET_LAYOUT](state, newLayout = 'Default') {
        state.layout = newLayout
    },
    [types.GET_LANGUAJE](state, data) {
        state.translates = data;
        // localStorage.setItem('languaje_keys', JSON.stringify(data));
    },
    [types.LANGUAJE_STRUCTURE](state, data) {
        state.languageStructure = data;
    },
    [types.CHANGED](state, data) {
        state.changed = data
    },
    [types.SET_COUNTRY_LIST](state, data) {
        state.countryList = data
    },
    [types.SET_LOADING_MESSAGE](state, message) {
        state.loadingMessage = message;
    },
    [types.SET_LOADING_ICON](state, message) {
        state.loadingIcon = message;
    },
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.GET_INIT_DATA](state) {
        const profile = JSON.parse(localStorage.getItem('profile'));
        this.commit('integration/SET_INTEGRATIONS', profile.integrationsCompany, {root: true})

        this.dispatch('general/setLayout', 'Default');

        router.push({name: 'init'});
    },
    [types.SPINNER](state) {
        this.dispatch('general/setLayout', 'Spinner');
        router.push({name: 'splash'});
    },
    [types.PRE_RECORDS_BARCODE](state, data) {
        state.pre_records_barcode = data;
    },
    [types.LABEL_DATA](state, data) {
        state.label_data = data;
    },

    [types.RECORD_EDIT](state, data) {
        state.record_edit = data;
    },

    [types.SET_CONFIG](state, data) {
        state.config = data;
    },
    [types.SET_MAP_CONFIG](state, data) {
        state.mapConfig = data;
    },
    [types.SET_ORIGINAL_STATE_CONFIG](state) {
        state.config = {
            structures: {
                simples: {
                    headers: [],
                    processed: []
                },
                byGroup: {}
            },
            reports: {
                general: {
                    userData: true
                }
            },
            company: {
                traceability: {
                    structure: {
                        number: {
                            afterPoint: null
                        }
                    }
                },
                export: {
                    recordExportLimit: null,
                    inspectionExportLimit: null
                }
            }
        };
    },
    [types.SET_COLOR](state, data) {
        state.color = data;
    },
}
