<template>
  <v-app>
    <splash></splash>
    <layout-navbar></layout-navbar>
    <layout-sidenav></layout-sidenav>
    <v-main class="fixed-padding" :style="isCustomDashboard ? 'background-color: #f6f5f5' : 'background-color: white'">
        <!-- Provides the application the proper gutter -->

        <!-- If using vue-router -->
        <router-view></router-view>

        <alert-component :data="nomenclatorAlert" />

        <!--<DrawerCustomizer />-->

    </v-main>
    <DataWS :userid="(profile.id).toString()" />
  </v-app>
</template>

<script>
  import LayoutNavbar from '@/components/layout/LayoutNavbar'
  import LayoutSidenav from '@/components/layout/LayoutSidenav'
  import AlertComponent from '@/components/AlertComponent'
  import DataWS from '@/module/configuration/notifications/components/DataWS'
  import DrawerCustomizer from "@customization/components/DrawerCustomizer.vue"
  import Splash from "@/components/Splash.vue"

  export default {
    components: {
      Splash,
      DrawerCustomizer,
      DataWS,
      AlertComponent,
      LayoutNavbar,
      LayoutSidenav,
    },

    data: () => ({
      primaryDrawer: {
        model: null,
      },
      profile: null
    }),

    created() {
      this.profile = JSON.parse(localStorage.getItem("profile"))
    },

    computed: {
      isCustomDashboard() {
        console.log(this.$route.path)
        return this.$route.path === '/custom/dashboard'
      },
      nomenclatorAlert: {
        get() {
          return this.$store.getters['ui/GET_NOMENCLATOR_DATA']
        },
        set(val) {
          this.$store.commit('ui/NOMENCLATOR_DATA', val)
        },
      },
    }
  }
</script>

<style scoped>
.fixed-padding {
  padding: 64px 0px 0px 256px;

  @media only screen and (min-width: 768px) {
    padding: 64px 0 0 64px;
  }
}
</style>
