import { ActionTree } from 'vuex';
import { CustomizationState, ThemeColors } from '@customization/models/ColorConfig';
import CustomizationService from '@customization/services/CustomizationService';
import { MutationTypes } from './mutations';
import { RootState } from '@customization/types/types';
import { defaultLightTheme } from "@customization/themes/default"

export enum ActionTypes {
  LOAD_CONFIGURATION = 'LOAD_CONFIGURATION',
  SAVE_CONFIGURATION = 'SAVE_CONFIGURATION',
  UPDATE_THEME_COLORS = 'UPDATE_THEME_COLORS',
}

const actions: ActionTree<CustomizationState, RootState> = {
  async [ActionTypes.LOAD_CONFIGURATION]({ commit }) {
    try {
      const response = await CustomizationService.getConfiguration();
      commit(MutationTypes.SET_THEME, response);
    } catch (error) {
      console.error('Error loading customization configuration:', error);
      commit(MutationTypes.SET_THEME, defaultLightTheme);
    }
  },
  async [ActionTypes.SAVE_CONFIGURATION]({ state }) {
    try {
      await CustomizationService.setConfiguration({ ...state.theme, defaultIdentity: true });
    } catch (error) {
      console.error('Error saving customization configuration:', error);
    }
  },
  [ActionTypes.UPDATE_THEME_COLORS]({ commit }, colors: ThemeColors) {
    commit(MutationTypes.UPDATE_THEME_COLORS, colors);
  },
};

export default actions;
