import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import RolesService from "../../../../services/RolesService";

export default {
    loadingON({commit, state}) {
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    async saveRoles({commit, state}, [item, editOrSave, toast]) {
        try {
            await RolesService.save(item, editOrSave);
            toast.success(i18n.t('roles.notification.save'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('roles.notification.inUse'), {
                    queueable: true,
                    icon: "mdi-alert-circle",
                });
            } else if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('roles.notification.saveError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
            }
        }
    },
    async fetchListModules({dispatch, commit, state}, [toast]) {
        state.modules = [];
        dispatch('loadingON');
        try {
            const {data} = await RolesService.modules();
            commit(types.SET_MODULE_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('roles.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },

    async fetchListRoles({dispatch, commit, state}, [profile, toast]) {
        state.roles = [];
        dispatch('loadingON');
        try {
            const {data} = await RolesService.roles(profile);

            let permission_by_modules = [];
            for (let role of data) {
                let tep = [];
                for (const permission of role.authorityList) {
                    if (tep.length) {
                        let newArr = tep.filter(module => module.module === permission.domain);
                        if (newArr.length) {
                            tep[tep.indexOf(newArr[0])].permission.push(permission.permission);
                            continue;
                        }
                    }
                    tep.push({
                        "module": permission.domain,
                        "permission": [permission.permission]
                    })
                }
                permission_by_modules.push({
                    "id": role.id,
                    "canDeleted": role.canDeleted,
                    "language_key": role.language_key,
                    "permissionByDomainDTOList": tep
                })
            }

            commit(types.SET_ROLE_LIST, permission_by_modules);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('roles.notification.listError'), {
                    icon: "mdi-alert-circle",
                    queueable: true
                });
        }
    },
    async deleteRol({commit, state}, [id, toast]) {
        try {
            await RolesService.delete(id);
            toast.success(i18n.t('roles.notification.deleted'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('roles.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('roles.notification.deletedError'), {
                        queueable: true
                    });
            }
        }
    }
}
