import { MutationTree } from 'vuex';
import { CustomizationState, Theme, ThemeColors } from "@customization/models/ColorConfig"
import { defaultLightTheme } from '@customization/themes/default';

export enum MutationTypes {
  SET_THEME = 'SET_THEME',
  UPDATE_THEME_COLORS = 'UPDATE_THEME_COLORS',
}

const mutations: MutationTree<CustomizationState> = {
  [MutationTypes.SET_THEME](state, theme: Theme) {
    state.theme = theme || defaultLightTheme;
  },
  [MutationTypes.UPDATE_THEME_COLORS](state, colors: ThemeColors) {
    state.theme.colors = colors;
  },
};

export default mutations;
