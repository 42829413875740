import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import GroupStructuresService from "../../../../services/GroupStructuresService";

export default {
    loadingON({commit, state}) {
        state.groupDetails = [];
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    async fetchGroupStructuresByCompany({dispatch, commit, state}, [profile,toast]) {
        dispatch('loadingON');
        try {
            const company = profile.company_id;
            const {data} = await GroupStructuresService.groupStructuresByCompany(company);
            commit(types.SET_GROUP_STRUCTURE_BY_COMPANY, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('groupStructure.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchGroupStructuresDefault({dispatch, commit, state}, [toast]) {
        dispatch('loadingON');
        try {
            const {data} = await GroupStructuresService.groupStructuresDefault();
            commit(types.SET_GROUP_STRUCTURE_DAFULTS, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('groupStructure.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchAllGroupStructures({dispatch, commit, state}, [toast]) {
        dispatch('loadingON');
        try {
            const {data} = await GroupStructuresService.allGroupStructures();
            commit(types.SET_GROUP_STRUCTURE, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('groupStructure.notification.listError'), {
                    queueable: true
                });
        }
    },
    async saveGroupStructures({dispatch, commit, state}, [item, editOrSave, toast]) {
        dispatch('loadingON');
        try {
            await GroupStructuresService.save(item, editOrSave);
            toast.success(i18n.t('groupStructure.notification.save'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('groupStructure.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('groupStructure.notification.saveError'), {
                        queueable: true
                    });
            }
        }
    },
    async deleteGroupStructure({commit, state}, [id, toast]) {
        try {
            await GroupStructuresService.delete(id);
            toast.success(i18n.t('groupStructure.notification.deleted'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('groupStructure.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('groupStructure.notification.deletedError'), {
                        queueable: true
                    });
            }
        }
    }
}
