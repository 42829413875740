import i18n from "@/plugins/i18n";
import * as types from "./mutations-type.js";
import SeasonsService from "../../../../services/SeasonsService";

export default {
  loading({ commit}, payload) {
    commit(types.SET_LOADING, payload);
  },
  setSeasonList({ commit }, items) {
    commit(types.SET_SEASON_LIST, items);
  },
  async fetchListSeasons({ dispatch, commit, state }, [filters, toast]) {
    try {
      dispatch("loading", true);
      
      const { data } = await SeasonsService.seasons(filters);
      commit(types.SET_SEASON_LIST, data);
      dispatch("loading", false);
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("season.notification.listError"), {
          queueable: true,
          color: "error"
        });
    }
  },
  async exportCSV({ dispatch, commit, state }, [language, toast]) {
    try {
      dispatch("loading", true);

      const { data } = await SeasonsService.export(language);
      const FileSaver = require("file-saver");
      const blob = new Blob([data], {
        type: "application/xlsx",
      });
      FileSaver.saveAs(blob, "seasons_data.xlsx");

      toast.success(i18n.t("season.notification.export"), {
        icon: 'mdi-check-circle',
        queueable: true,
      });

      dispatch("loading", false);
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("season.notification.exportError"), {
          queueable: true,
          color: "error"
        });

      dispatch("loading", false);
    }
  },
  async saveSeason(
    { dispatch, commit, state },
    [item, documents, editOrSave, toast]
  ) {
    try {
      dispatch("loading", true);

      await SeasonsService.save(item, documents, editOrSave);
      toast.success(i18n.t("season.notification.save"), {
        queueable: true,
        icon: 'mdi-check-circle',
      });
    } catch (error) {
      if (error.message.includes("406")) {
        toast.error(i18n.t("season.notification.inUse"), {
          queueable: true,
          color: "gray",
        });
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("season.notification.saveError"), {
            queueable: true,
            color: "error",
          });
      }
      dispatch("loading", false);
    }
  },
  async deleteSeason({ commit, state }, [id, toast]) {
    try {
      await SeasonsService.delete(id);
      toast.success(i18n.t("season.notification.deleted"), {
        queueable: true,
        icon: 'mdi-check-circle',
      });
    } catch (error) {
      if (error.message.includes("406")) {
        toast.error(i18n.t("season.notification.inUse"), {
          queueable: true,
          color: "gray",
        });
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("season.notification.deletedError"), {
            queueable: true,
            color: "error",
          });
      }
    }
  },
};
