import i18n from "@/plugins/i18n";
import * as types from "./mutations-type.js";
import ProducerService from "../../../../services/ProducerService";

export default {
  loading({commit}, payload) {
    commit(types.SET_LOADING, payload);
  },

  async validateField({ commit }, fieldData) {
    try {
      const response = await ProducerService.checkFields(fieldData);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  async fetchListProducer({dispatch, commit}, [filters, toast]) {
    dispatch("loading", true);
    try {
      const {data} = await ProducerService.producers(filters);

      commit(types.SET_PRODUCERS_LIST, data);

    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("producer.notification.listError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });
    } finally {
      dispatch("loading", false);
    }
  },
  async fetchAllProducer({dispatch, commit}) {
    try {
      const {data} = await ProducerService.all();

      commit(types.SET_All_PRODUCERS_LIST, data);
    } catch (error) {
      if (error.message !== "cancelRequest")
        this._vm.$toast.error(i18n.t("producer.notification.listError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });
    }
  },
  async exportCSV({dispatch, commit, state}, [language, toast]) {
    try {
      const {data} = await ProducerService.export(language);
      const FileSaver = require("file-saver");
      const blob = new Blob([data], {
        type: "application/xlsx",
      });
      FileSaver.saveAs(blob, "producers_data.xlsx");

      toast.success(i18n.t("producer.notification.export"), {
        icon: 'mdi-check-circle',
        queueable: true,
      });
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("producer.notification.exportError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });
    }
  },
  async saveProducer(
    {dispatch, commit, state},
    [item, editOrSave, toast]
  ) {
    try {
      await ProducerService.save(item, editOrSave);
      toast.success(i18n.t("producer.notification.save"), {
        icon: 'mdi-check-circle',
        queueable: true,
      });
    } catch (error) {
      console.log(error)
      if (error.response.data.status === 406) {
        if (['BRAZILIAN_CPF_WRONG_FORMAT', 'BRAZILIAN_RG_WRONG_FORMAT'].includes(error.response.data.message)) {
          toast.error(i18n.t(`producer.notification.${error.response.data.message}`), {
            icon: "mdi-alert-circle",
            queueable: true
          });
        } else {
          toast.info(i18n.t("producer.notification.inUse"), {
            icon: 'mdi-information-slab-circle',
            queueable: true,
          });
        }
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("producer.notification.saveError"), {
            icon: "mdi-alert-circle",
            queueable: true,
          });
      }
    }
  },
  async deleteProducer({commit, state}, [id, toast]) {
    try {
      await ProducerService.delete(id);
      toast.success(i18n.t("producer.notification.deleted"), {
        icon: 'mdi-check-circle',
        queueable: true,
      });
    } catch (error) {
      if (error.message.includes("406")) {
        toast.info(i18n.t("producer.notification.inUse"), {
          icon: 'mdi-information-slab-circle',
          queueable: true,
        });
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("producer.notification.deletedError"), {
            icon: "mdi-alert-circle",
            queueable: true,
          });
      }
    }
  },
};
