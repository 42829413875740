import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { Module } from 'vuex';
import { CustomizationState } from '@customization/models/ColorConfig';
import { RootState } from "@customization/types/types"

const customization: Module<CustomizationState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default customization;
