import * as types from "./mutations-type.js";
import TrackingService from "../../../services/TrackingService";
import i18n from "@/plugins/i18n"

export default {
  loadingON({ commit, state }) {
    state.trackingData = [];
    commit(types.SET_LOADING_ON, true);
  },
  loadingOFF({ commit }) {
    commit(types.SET_LOADING_OFF, false);
  },

  final({ commit }) {
    commit(types.FINAL);
  },
  async findRecordTrackingById({ dispatch, commit, state }, [id, toast]) {
    dispatch("loadingON");
    try {
      const { data } = await TrackingService.recordByQrId(id);

      commit(types.SET_RECORD_TRACKING_DATA, data);
      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("records.notification.listError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });
    }
  },
};
