import { default as http } from '@/services/HttpService';
import { Theme } from "@customization/models/ColorConfig"
import { defaultLightTheme } from "@customization/themes/default"

class CustomizationService {
    async getConfiguration(): Promise<Theme> {
        try {
            const response = await http.client.get<Theme>('/v1/company-design-identity');
            return response.data;
        } catch (error) {
            console.error('Error fetching configuration:', error);
            return defaultLightTheme;
        }
    }

    async setConfiguration(payload: Theme): Promise<void> {
        const formData = new FormData();
        formData.append('design_json', JSON.stringify(payload));

        if (!payload.id)
            await http.client.post('/v1/company-design-identity', formData);
        else
            await http.client.put('/v1/company-design-identity', formData);
    }
}

export default new CustomizationService();
