import {default as http} from './HttpService'

class DocumentTypeService {
    constructor() {
    }

    documentTypes() {
        return http.client.get(`v1/document-type`);
    }

    getDocumentTypesByCompany() {
        return http.client.get(`v1/document-type/by-company`);
    }

    save(item, editOrSave) {
        if (editOrSave > -1) {
            return http.client.put('/v1/document-type', item);
        } else {
            return http.client.post('/v1/document-type', item);
        }
    }

    delete(id) {
        return http.client.delete('/v1/document-type/' + id);
    }

    getDocumentTypesFiltered(payload) {
        return http.client.post('v1/document-type/filter', payload)
    }
}

export default new DocumentTypeService()
