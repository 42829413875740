import Vue from 'vue';
import App from './App.vue';

// Init customization microfrontend
import "@customization/configuration/init";

import vuetify from '@customization/plugins/vuetify';

// Init dashboard microfrontend
import "@dashboard/configuration/init";

// Init abrapa integration microfrontend
import "@integrations/abrapa/configuration/init";

import LottieAnimation from 'lottie-web-vue';
Vue.use(LottieAnimation);

import VCurrencyField from 'v-currency-field'

import store from './store';
import router from './router';
import i18n from './plugins/i18n';

// Global Filters
import filters from './filters';
Vue.use(filters);

import DatetimePicker from './components/vuetify-datetime-picker';
Vue.use(DatetimePicker);

import VueMask from 'v-mask'
Vue.use(VueMask);

import { VTextField } from 'vuetify/lib'
Vue.component('v-text-field', VTextField)

Vue.use(VCurrencyField, {
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
});
Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
    console.log = function () {} // override the log function to hide logs
    console.error = function () {} // override the log function to hide logs
    console.warn = function () {} // override the log function to hide logs
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
