import { render, staticRenderFns } from "./LanguajeSelect.vue?vue&type=template&id=a2f1b202&scoped=true"
import script from "./LanguajeSelect.vue?vue&type=script&lang=js"
export * from "./LanguajeSelect.vue?vue&type=script&lang=js"
import style0 from "./LanguajeSelect.vue?vue&type=style&index=0&id=a2f1b202&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2f1b202",
  null
  
)

export default component.exports