export const SET_LAYOUT = 'SET_LAYOUT';
export const GET_LANGUAJE = 'GET_LANGUAJE';
export const LANGUAJE_STRUCTURE = 'LANGUAJE_STRUCTURE';
export const CHANGED = 'CHANGED';
export const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST';
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_MESSAGE = 'SET_LOADING_MESSAGE';
export const SET_LOADING_ICON = 'SET_LOADING_ICON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const GET_INIT_DATA = 'GET_INIT_DATA';
export const SPINNER = 'SPINNER';

export const LABEL_DATA = 'LABEL_DATA';
export const PRE_RECORDS_BARCODE = 'PRE_RECORDS_BARCODE';
export const RECORD_EDIT = 'RECORD_EDIT';

export const SET_CONFIG = 'SET_CONFIG';
export const SET_MAP_CONFIG = 'SET_MAP_CONFIG';
export const SET_ORIGINAL_STATE_CONFIG = 'SET_ORIGINAL_STATE_CONFIG';
export const SET_COLOR = 'SET_COLOR';