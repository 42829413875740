
import Vue from 'vue';
import Default from './layouts/Default.vue';
import Login from './layouts/Login.vue';
import Tracking from './layouts/Tracking.vue';
import {mapGetters,mapActions} from 'vuex';
import Final from "./layouts/Final.vue";
import L_QrLabel from "./layouts/L_QrLabel.vue";

export default Vue.extend({
  name: 'App',

  components: {
    Default,
    Login,
    Tracking,
    Final,
    L_QrLabel
  },
  beforeCreate() {
    this.$store.commit('login/LOAD_INIT_DATA');
    this.$i18n.locale = localStorage.getItem('locale') || 'pt-br';
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      layout: 'general/layout',
      isLoggedIn: 'login/isLoggedIn'
    }),
  },
  mounted() {
    // do something based on the navigation type...
    if(this.isLoggedIn){
      this.browserAction();
    }
  },
  methods: {
    ...mapActions({
      getInitData: 'general/getInitData',
    }),
    browserAction() {
      // do something based on the navigation type...
      switch (window.performance.navigation.type) {
        case 0:
          console.info("TYPE_NAVIGATE");
          this.getInitData([this.$toast, true]);
          break;
        case 1:
          console.info("TYPE_RELOAD");
          this.getInitData([this.$toast, true]);
          break;
        case 2:
          console.info("TYPE_BACK_FORWARD");
          break;
        case 255:
          console.info("255");
          break;
      }
    },
  }
});
