import i18n from "@/plugins/i18n";
import * as types from "./mutations-type.js";
import InputService from "../../../../services/InputService";

export default {
  loadingON({ commit, state }) {
    commit(types.SET_LOADING_ON, true);
  },
  loadingOFF({ commit }) {
    commit(types.SET_LOADING_OFF, false);
  },
  setInputsList({ commit }, items) {
    commit(types.SET_INPUT_LIST, items);
  },
  async fetchListInputs({ dispatch, commit, state }, [filters, toast]) {
    dispatch("loadingON");
    try {
      const { data } = await InputService.inputs(filters);
      commit(types.SET_INPUT_LIST, data);
      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("input.notification.listError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });
    }
  },
  async saveInput(
    { dispatch, commit, state },
    [item, documents, editOrSave, toast]
  ) {
    dispatch("loadingON");
    try {
      await InputService.save(item, documents, editOrSave);
      toast.success(i18n.t("input.notification.save"), {
        icon: 'mdi-check-circle',
        queueable: true
      });
    } catch (error) {
      if (error.message.includes("406")) {
        toast.error(i18n.t("input.notification.inUse"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("input.notification.saveError"), {
            icon: "mdi-alert-circle",
            queueable: true,
          });
      }
    }
  },
  async deleteInput({ commit, state }, [id, toast]) {
    try {
      await InputService.delete(id);
      toast.success(i18n.t("input.notification.deleted"), {
        icon: 'mdi-check-circle',
        queueable: true,
      });
    } catch (error) {
      if (error.message.includes("406")) {
        toast.error(i18n.t("input.notification.inUse"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });
      } else {
        if (error.message !== "cancelRequest")
          toast.error(i18n.t("input.notification.deletedError"), {
            icon: "mdi-alert-circle",
            queueable: true,
          });
      }
    }
  },
  async exportCSV({ dispatch, commit, state }, [language, toast]) {
    dispatch("loadingON");
    try {
      const { data } = await InputService.export(language);
      const FileSaver = require("file-saver");
      const blob = new Blob([data], {
        type: "application/xlsx",
      });
      FileSaver.saveAs(blob, "input_data.xlsx");

      toast.success(i18n.t("input.notification.export"), {
        icon: 'mdi-check-circle',
        queueable: true,
      });

      dispatch("loadingOFF");
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("input.notification.exportError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        });

      dispatch("loadingOFF");
    }
  },
};
