import i18n from '@/plugins/i18n';
import * as types from './mutations-type.js';
import StructuresService from "../../../../services/StructuresService";

export default {
    loadingON({commit, state}) {
        state.details = [];
        commit(types.SET_LOADING_ON, true);
    },
    loadingOFF({commit}) {
        commit(types.SET_LOADING_OFF, false);
    },
    async fetchListStructureLots({dispatch, commit, state}, [profile, filters, toast]) {
        dispatch('loadingON');
        try {
            const {data} = await StructuresService.structuresLot(profile.company_id, filters);
            console.log(data)
            commit(types.SET_LOTS, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.response.status === 404) {
                // toast.warning(i18n.t('notifications.structures.404.lots.NOT_FOUND'), {
                //     queueable: true,
                //     color: 'gray'
                // });
                return
            }
            if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('structure.notification.listError'), {
                    queueable: true
                });
            }
        }
    },
    async fetchListStructureProducts({dispatch, commit, state}, [profile, lot_id, toast, persist = true]) {
        try {
            const { data } = await StructuresService.searchProductsByLot(profile.company_id, lot_id);
            if (persist) commit(types.SET_PRODUCTS, data);
            else return data;
        } catch (error) {
            if (error.response.status === 404) {
                // toast.warning(i18n.t('notifications.structures.404.lots.NOT_FOUND'), {
                //     queueable: true,
                //     color: 'gray'
                // });
                return
            }
            if (error.message !== 'cancelRequest') {
                toast.error(i18n.t('structure.notification.listError'), {
                    queueable: true
                });
            }
        }
    },
    async fetchListStructuresByCode({dispatch, commit, state}, [toast]) {
        dispatch('loadingON');
        try {
            const {data} = await StructuresService.structuresByCode();
            commit(types.SET_STRUCTURE_LIST_CODE, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('structure.notification.listError'), {
                    queueable: true
                });
        }
    },
    async fetchListStructures({dispatch, commit, state}, [profile, toast]) {
        dispatch('loadingON');
        try {
            const company = profile.company_id;
            const {data} = await StructuresService.structuresByCompany(company);
            commit(types.SET_STRUCTURE_LIST, data);
            dispatch('loadingOFF');
        } catch (error) {
            if (error.message !== 'cancelRequest')
                toast.error(i18n.t('structure.notification.listError'), {
                    queueable: true
                });
        }
    },
    async saveStructures({dispatch, commit, state}, [item, editOrSave, toast]) {
        dispatch('loadingON');
        try {
            await StructuresService.save(item, editOrSave);
            toast.success(i18n.t('structure.notification.save'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('structure.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('structure.notification.saveError'), {
                        queueable: true
                    });
            }
        }
    },
    async deleteStructure({commit, state}, [id, toast]) {
        try {
            await StructuresService.delete(id);
            toast.success(i18n.t('structure.notification.deleted'), {
                queueable: true,
                icon: 'mdi-check-circle',
            });
        } catch (error) {
            if (error.message.includes('406')) {
                toast.error(i18n.t('structure.notification.inUse'), {
                    queueable: true,
                    color: 'gray'
                });
            } else {
                if (error.message !== 'cancelRequest')
                    toast.error(i18n.t('structure.notification.deletedError'), {
                        queueable: true
                    });
            }
        }
    }
}
