<template>
  <fragment style="height: 400px">
    <template>
      <div class='text-left'>
        <v-menu
          v-model='menu'
          :close-on-content-click='false'
          :nudge-width='150'
          max-height="400px"
          offset-x
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn class='mt-2 ml-3'
                   dark
                   v-bind='attrs'
                   v-on='on'
                   fab
                   x-small
                   elevation='0'
                   tile
                   color='secondary'
            >
              <v-icon class='pl-3' color='white'>mdi-layers-triple
              </v-icon>
              <v-badge
                class='pb-3 ml-3'
                color="error"
                :content="zoom"
              >
              </v-badge>
            </v-btn>
          </template>

          <v-card style="max-height: 400px;">
            <v-card-title class="py-2 font-weight-regular secondary t-bw-secondary--text">
              {{ $t('general.buttons.layers') }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="max-height: 200px; overflow-y: auto;">
              <v-list
                dense
              >
                <v-list-item dense @click="() => filterAllMap(!areAllChecked)">
                  <v-list-item-action >
                    <v-icon :color="areAllChecked? 'primary': '' ">{{ areAllChecked ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content class="font-weight-bold">
                    {{ areAllChecked ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
                  </v-list-item-content>
                </v-list-item>
                  <v-list-item dense v-for='(layer, index) in layers' :key='index'>
                    <v-list-item-action @click='layerMap(layer)'>
                      <v-checkbox v-model='layer.checked'></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('map.layers.'+layer.id).toUpperCase() }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click='menu=false'>
                {{ $t('general.buttons.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </template>
  </fragment>
</template>
<script>
  import _ from 'lodash'

  export default {
    name: 'MapLayers',
    inject: ['mapbox', 'map', 'actions'],
    props: [
      'layers',
      'mapboxMap',
      'zoom',
    ],
    data() {
      return {
        menu: false
      }
    },
    computed: {
      areAllChecked() {
        return this.layers.every(layer => layer.checked);
      }
    },

    methods: {
      filterAllMap(value) {
        _.forEach(this.layers, (layer) => {
          layer.checked = value
          this.layerMap(layer)
        })
        // this.$emit('layerMap', this.layers)
      },
      layerMap(layer) {
        if (this.mapboxMap.getLayer(layer.id)) {
          if (layer.checked) {
            this.mapboxMap.setLayoutProperty(layer.id, 'visibility', 'visible')
          } else {
            this.mapboxMap.setLayoutProperty(layer.id, 'visibility', 'none')
          }
        }
      },
    },
  }
</script>
<style scoped>
  .badge {
    background-color: #9d1717;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: 5px;
  }
</style>