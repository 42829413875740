<template>
    <v-card v-if="layerInfo===true" height="100%" style="position: absolute;top: 0; right: 0; z-index: 100">
        <v-list min-width="280px" class="pb-0 pt-0">
            <v-list-item two-line style="font-size: 16px; color: black">
                <v-icon color="black" class="mr-5"
                        @click.stop="close">
                    mdi-chevron-right
                </v-icon>
                <v-list-item-content class="pt-0">
                    <v-list-item-title class="mt-6" style="color: black">
                        {{selectedFeature.primaryName?selectedFeature.primaryName:selectedFeature.locationName}}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px; color: gray">
                        <v-icon v-if="selectedFeature.primaryName" small color="primary" class="mr-1">
                            mdi-map-marker-radius-outline
                        </v-icon>
                        {{selectedFeature.primaryName?selectedFeature.storageName:'-'}}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list
                dense
                v-if="selectedFeature"
        >
            <v-list-item style="background-color: #eeeeee">
                <v-list-item-content>
                    <v-list-item-title>
                        <v-row>
                            <v-col  cols="6" style="font-size: 12px; color: slategray">
                                {{$t('storage.fields.gln') }}
                            </v-col>
                            <v-col  cols="6" style="font-size: 12px; color: slategray">
                                {{$t('storage.fields.area')}}
                            </v-col>
                        </v-row>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item style="background-color: #FFFFFF">
                <v-list-item-content>
                    <v-list-item-title>
                        <v-row>
                            <v-col  cols="6" style="font-size: 12px; color: #6c757d">
                                {{selectedFeature.gln?selectedFeature.gln:'-'}}
                            </v-col>
                            <v-col  cols="6" style="font-size: 12px; color: #6c757d">
                                {{selectedFeature.area?selectedFeature.area:'-'}}
                            </v-col>
                        </v-row>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item style="background-color: #eeeeee">
                <v-list-item-content>
                    <v-list-item-title>
                        <v-row>
                            <v-col  cols="6" style="font-size: 12px; color: slategray">
                                {{$t('locations.fields.telephone')}}
                            </v-col>
                            <v-col  cols="6" style="font-size: 12px; color: slategray">
                                {{$t('locations.fields.email')}}
                            </v-col>
                        </v-row>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item style="background-color: #FFFFFF">
                <v-list-item-content>
                    <v-list-item-title>
                        <v-row>
                            <v-col  cols="6" style="font-size: 12px; color: #6c757d">
                                {{selectedFeature.telephone?selectedFeature.telephone:'-'}}
                            </v-col>
                            <v-col  cols="6" style="font-size: 12px; color: #6c757d">
                                {{selectedFeature.email?selectedFeature.email:'-'}}
                            </v-col>
                        </v-row>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>
<script>

    export default {
        name: "MapInfofeatureSelect",
        props: [
            'selectedFeature',
            'layerInfo'
        ],
        data() {
            return {};
        },
        computed: {},

        created() {

        },

        methods: {
            close() {
                this.$emit('closeInfo');
            }
        }
    };
</script>