import { Theme } from '@customization/models/ColorConfig';

export const defaultLightTheme: Theme = {
  id: null,
  logo: null,
  defaultIdentity: true,
  colors: {
    primary: '#03AC8C',
    secondary: '#232323',
    accent: '#82B1FF',
    error: '#e83b36',
    info: '#2196f3',
    success: '#289F89',
    warning: '#E8BD30',
    neuter: '#232323',
    white: '#FFFFFF',
    gray: '#eaeaea',
    metal: '#ebebeb',
    validating: '#2f2f2f',
    pending: '#ffc107',
    disabled: '#ebebeb',
    surface: '#F2F2F2'
  },
};
