export default {
  regex: {
    cpf: '[0-9]{3}\\.?[0-9]{3}\\.?[0-9]{3}\\-?[0-9]{2}',
    rg: '^(\\d{1,2}\\.?)(\\d{3}\\.?)(\\d{3})(\\-?[0-9a-zA-Z]{1,2})?$',
    email: '([\\w._%+-]+)(@|\\s@\\s|\\sat\\s|\\[at\\])([\\w.-]+)\\.([\\w]{2,})',
    telefone: '(?:(?:(\\+|00)?(55))\\s?)?(?:\\(?(\\d{2})\\)?\\s?)(|\\d{2})(|-)?(?:(9\\d|[2-9])\\d{3}[-|.|\\s]?(\\d{4}))'
  },
  layout: 'Default',
  countryList: [{name:"Brasil"},{name:"Portugal"}],
  changed: false,
  loading: true,
  loadingMessage: '',
  loadingIcon: '',
  translates: [],
  languageStructure: [],
  label_data: null,
  pre_records_barcode: null,
  record_edit: null,
  mapConfig: {
    sources: [
      {
        id: "s_locations_clusters",
        config:{
          type: "geojson",
          data: { type: "FeatureCollection", features: [] },
          cluster: true,
          clusterMaxZoom: 8, // Max zoom to cluster points on
          clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        }
      },
      {
        id: 's_locations',
        config: {
          type: 'geojson',
          data: {type: 'FeatureCollection', features: []}
        }
      },
      {
        id: "s_storages_clusters",
        config:{
          type: "geojson",
          data: { type: "FeatureCollection", features: [] },
          cluster: true,
          clusterMaxZoom: 18, // Max zoom to cluster points on
          clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        }
      },
      {
        id: 's_storages',
        config: {
          type: 'geojson',
          data: {type: 'FeatureCollection', features: []}
        }
      }
    ],
    layers: [
      {
        id: 'l_locations_clusters',
        type: 'circle',
        source: 's_locations_clusters',
        filter: ['has', 'point_count'],
        layout: {
          visibility: 'none'
        },
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#f6b0ae',
            10,
            '#f6b0ae',
            25,
            '#f6b0ae',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            10,
            30,
            25,
            40,
          ],
        },
        minzoom: 1,
        maxzoom: 8
      },
      {
        id: "l_locations_clusters_count",
        type: "symbol",
        source: "s_locations_clusters",
        layout: {
          'visibility': 'none',
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        filter: ['has', 'point_count'],
        minzoom: 1,
        maxzoom: 8
      },
      {
        id: 'l_locations_up_zoom',
        type: 'fill',
        source: 's_locations',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'fill-color': 'rgb(246,176,174)',
          'fill-outline-color': 'rgb(22,22,22)',
          'fill-opacity': 0.5
        },
        minzoom: 6,
        maxzoom: 11
      },
      {
        id: 'l_locations_down_zoom',
        type: 'fill',
        source: 's_locations',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'fill-color': 'rgb(246,176,174)',
          'fill-outline-color': 'rgb(22,22,22)',
          'fill-opacity': 0.5
        },
        minzoom: 8,
        maxzoom: 11
      },
      {
        id: 'l_locations_clusters_points',
        type: 'circle',
        source: 's_locations_clusters',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'visibility': 'none'
        },
        paint: {
          'circle-color': '#b91212',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        },
        minzoom: 1,
        maxzoom: 8
      },
      {
        id: 'l_locations_clusters_points_icon',
        type: 'symbol',
        source: 's_locations_clusters',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'visibility': 'none',
          'icon-image': 'redIcon',
          'icon-size': 0.75,
          'icon-anchor': 'bottom',
          'icon-offset': [0, 5]
        },
        minzoom: 1,
        maxzoom: 8
      },
      {
        id: 'l_locations_center_points',
        type: 'circle',
        source: 's_locations_clusters',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'circle-color': '#b91212',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        },
        minzoom: 8,
        maxzoom: 18
      },
      {
        id: 'l_locations_center_points_icon',
        type: 'symbol',
        source: 's_locations_clusters',
        layout: {
          'visibility': 'none',
          'icon-image': 'redIcon',
          'icon-size': 0.75,
          'icon-anchor': 'bottom',
          'icon-offset': [0, 5]
        },
        minzoom: 8,
        maxzoom: 18
      },
      {
        id: 'l_locations_names',
        type: 'symbol',
        source: 's_locations',
        layout:{
          'visibility': 'none',
          'symbol-placement': 'point',
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],

          'text-field': ['format',
            ['upcase', ['get', 'locationName']], {'font-scale': 1.1}]
        },
        paint:{
          'text-color': '#ffffff',
          'text-halo-color': '#050000',
          'text-halo-width': 1,
        },
        minzoom: 9,
        maxzoom: 16
      },
      {
        id: 'l_locations_fill_all',
        type: 'fill',
        source: 's_locations',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'fill-color': 'rgb(246,176,174)',
          'fill-outline-color': 'rgb(22,22,22)',
          'fill-opacity': 0.5
        },
        minzoom: 10,
        maxzoom: 24
      },
      {
        id: 'l_locations_all',
        type: 'symbol',
        source: 's_locations',
        layout: {
          'visibility': 'none',
          'icon-image': 'redIcon',
          'icon-size': 0.75,
          'icon-anchor': 'bottom',
          'icon-offset': [0, 5]
        },
        minzoom: 0,
        maxzoom: 24
      },


      {
        id: 'l_storages_clusters',
        type: 'circle',
        source: 's_storages_clusters',
        filter: ['has', 'point_count'],
        layout: {
          visibility: 'none'
        },
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#8dea9c',
            10,
            '#8dea9c',
            25,
            '#8dea9c',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            10,
            30,
            25,
            40,
          ],
        },
        minzoom: 6,
        maxzoom: 10
      },
      {
        id: "l_storages_clusters_count",
        type: "symbol",
        source: "s_storages_clusters",
        layout: {
          'visibility': 'none',
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        filter: ['has', 'point_count'],
        minzoom: 6,
        maxzoom: 10
      },
      {
        id: 'l_storages_up_zoom',
        type: 'fill',
        source: 's_storages',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'fill-color': 'rgb(4,73,210)',
          'fill-outline-color': 'rgb(22,22,22)',
          'fill-opacity': 0.5
        },
        minzoom: 7,
        maxzoom: 18
      },
      {
        id: 'l_storages_down_zoom',
        type: 'fill',
        source: 's_storages',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'fill-color': 'rgb(4,73,210)',
          'fill-outline-color': 'rgb(22,22,22)',
          'fill-opacity': 0.5
        },
        minzoom: 10,
        maxzoom: 18
      },
      {
        id: 'l_storages_clusters_points',
        type: 'circle',
        source: 's_storages_clusters',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'visibility': 'none'
        },
        paint: {
          'circle-color': 'rgb(4,73,210)',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        },
        minzoom: 6,
        maxzoom: 10
      },
      {
        id: 'l_storages_clusters_points_icon',
        type: 'symbol',
        source: 's_storages_clusters',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'visibility': 'none',
          'icon-image': 'blueIcon',
          'icon-size': 0.75,
          'icon-anchor': 'bottom',
          'icon-offset': [0, 5]
        },
        minzoom: 6,
        maxzoom: 10
      },
      {
        id: 'l_storages_center_points',
        type: 'circle',
        source: 's_storages_clusters',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'circle-color': 'rgb(4,73,210)',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        },
        minzoom: 10,
        maxzoom: 18
      },
      {
        id: 'l_storages_center_points_icon',
        type: 'symbol',
        source: 's_storages_clusters',
        layout: {
          'visibility': 'none',
          'icon-image': 'blueIcon',
          'icon-size': 0.75,
          'icon-anchor': 'bottom',
          'icon-offset': [0, 5]
        },
        minzoom: 10,
        maxzoom: 18
      },
      {
        id: 'l_storages_names',
        type: 'symbol',
        source: 's_storages',
        layout:{
          'visibility': 'none',
          'symbol-placement': 'point',
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],

          'text-field': ['format',
            ['upcase', ['get', 'locationName']], {'font-scale': 1.1}]
        },
        paint:{
          'text-color': '#ffffff',
          'text-halo-color': '#050000',
          'text-halo-width': 1,
        },
        minzoom: 9,
        maxzoom: 16
      },
      {
        id: 'l_storages_all',
        type: 'symbol',
        source: 's_storages',
        layout: {
          'visibility': 'none',
          'icon-image': 'blueIcon',
          'icon-size': 0.75,
          'icon-anchor': 'bottom',
          'icon-offset': [0, 5]
        },
        minzoom: 0,
        maxzoom: 24
      },

    ],
  },

  config: {
    structures: {
      simples: {
        headers: [],
        processed: []
      },
      byGroup: {}
    },
    reports: {
      general: {
        userData: true
      }
    },
    company: {
      traceability: {
        structure: {
          number: {
            afterPoint: null
          }
        }
      },
      export: {
        recordExportLimit: null,
        inspectionExportLimit: null
      }
    }
  },
  color: '#FFFFFF'
}
