<template>
  <v-app-bar :clipped-left="true" app color="primary" class="custom--toolbar">
    <v-toolbar-title>
      <div v-if="profile" class="ml-4" style="height: 55px; width: auto; overflow: hidden;">
        <img
          v-if="profile.companyLogo && profile.companyLogo.s3_url"
          :src="profile.companyLogo.s3_url"
          alt="Company Logo"
          style="width: 100%; height: 100%; object-fit: contain;"
        />
        <h3 v-else :class="{ 'ml-4': !profile.companyLogo }" class="t-bw-primary--text">
          {{ profile.company_name_key }}
        </h3>
      </div>

    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div class="navbar-styles flex-row-reverse secondary">
      <change-password ref="changePass"></change-password>
      <div class="mx-4 d-flex justify-center align-center">
        <v-menu
          :close-on-content-click="false"
          :nudge-bottom="55"
          max-width="50%"
          transition="slide-y-transition"
          style="z-index: 999"
          content-class="custom-menu__elevation"
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <v-icon size="20px" class="ma-1 mouse-pointer" color="t-bw-secondary"
                >mdi-cog</v-icon
              >
            </span>
          </template>
          <v-list elevation="0" class="mt-0 pt-0">
            <v-list-item class="secondary">
              <v-icon
                light
                class="pr-2"
                color="t-bw-secondary"
                right
                small
              >
                mdi-account-circle-outline
              </v-icon>
              <v-list-item-title class="t-bw-secondary--text">{{
                $t("company.titles.configurations")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'notifications' }">
              <v-icon class="pr-2" color="dark" right small>mdi-bell-outline</v-icon>
              <v-list-item-title>{{
                $t("notifications.module.titles.notifications")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changePassDialog">
              <v-icon class="pr-2" color="dark" right small>mdi-key-change</v-icon>
              <v-list-item-title>{{
                $t("auth.fields.changePass")
              }}</v-list-item-title>
            </v-list-item>
            <!--<v-list-item @click="logout()">
              <v-icon class="pr-2" dark right small>mdi-logout</v-icon>
              <v-list-item-title>{{
                $t("auth.fields.logout")
              }}</v-list-item-title>
            </v-list-item>-->
          </v-list>
        </v-menu>

        <SelectCompany />

        <NotificationsComponent />

        <v-btn
          fab
          text
          small
          dark
          @click="logout()"
        >
          <v-icon size="20px" color="t-bw-secondary" text>
            mdi-logout
          </v-icon>
        </v-btn>
      </div>
      <v-divider class="mx-1 t-bw-secondary"
                 inset
                 vertical>
      </v-divider>
      <languaje-select
        background="transparent"
        :dense="false"
        :flagH="14"
        :flagW="22"
        :flat="true"
        class="ml-3"
        style="width: 70px"
      ></languaje-select>
      <v-divider
        v-if="isIgPresent"
        class="mx-1 t-bw-secondary"
        inset
        vertical
      ></v-divider>
      <v-img
        v-if="isIgPresent"
        class="logos-header pr-0"
        :style="{ filter: isColorDark(getColorFromTheme('secondary', $vuetify)) ? 'brightness(0) invert(1)' : 'brightness(0)' }"
        height="150px"
        :src="footerLogos"
      ></v-img>
    </div>
  </v-app-bar>
</template>
<script>
  import LanguajeSelect from "@/components/LanguajeSelect"
  import ChangePassword from "@/components/ChangePassword"
  import NotificationsComponent from "@/module/configuration/notifications/components/NotificationsComponent"
  import { getColorFromTheme, isColorDark } from "@customization/utils"
  import SelectCompany from "@/components/layout/common/SelectCompany.vue"

  export default {
    name: "app-layout-navbar",
    components: { SelectCompany, NotificationsComponent, ChangePassword, LanguajeSelect },
    data: () => ({
      dialogChange: false,
      logo: require("@/assets/img/logo.png"),
      email: "",
      integrations: {
        IG: null,
      },
      profile: null,
      footerLogos: require("@/assets/img/Logos.png"),
      isIgPresent: false,
    }),
    created() {
      this.profile = localStorage.getItem("profile")
        ? JSON.parse(localStorage.getItem("profile"))
        : []
      this.integrations.IG = this.$store.getters['integration/IS_PRESENT']('IG');

      if (this.integrations.IG) {
        this.isIgPresent = true;
      }

      this.email = this.profile.email
    },
    computed: {},
    methods: {
      getColorFromTheme,
      isColorDark,
      changePassDialog() {
        this.$refs.changePass.openDialog()
      },
      logout() {
        localStorage.clear()
        this.$router.push("/login")
        this.$router.go()
      },
    },
  }
</script>
<style lang="scss">
  @import "../../../src/assets/css/variables.scss";

  $btn-text-transform: "none";

  .navbar-styles {
    height: 100%;
    display: flex !important;
    justify-items: center !important;
    align-items: center !important;
    cursor: pointer !important;
  }

  .custom--toolbar {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px !important;

    .v-toolbar__content,
    .v-toolbar__extension {
      padding: 0px !important;
    }
  }

  .logos-header {
    height: 100%;
    width: 300px;
  }
</style>