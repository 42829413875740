<template>
  <div>
    <v-autocomplete
      :items="items"
      item-text="name"
      item-value="id"
      :label="fieldName"
      :placeholder="$t('general.fields.startTyping')"
      return-object
      :search-input.sync="search"
      v-model="selected"
      persistent-hint
      :solo="solo"
      small
      :outlined="outlined"
      :dense="dense"
      small-chips
      hide-details
      :clearable="clearable"
      :multiple="multiple"
      color="primary"
      item-color="primary"
      :disabled="disabled"
      @keydown.enter="updateSearchInput($event)"
      @change="$emit('data:change', selected)"
      @click:clear="clearInput()"
      :loading="loading"
      :background-color="background"
      :flat="flat"
    >
      <template #append v-if="!disabled">
        <v-btn icon small text @click="updateSearchInput(search)">
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>
      </template>
      <template #selection="{ item, index }">
        <v-chip small v-if="index === 0">
          <span>{{ item.name | truncate(10) }}</span>
        </v-chip>
        <span v-if="index === 1 && multiple" class="grey--text caption">
            (+{{ byPage - 1 }} {{$t('general.titles.perPage')}})
        </span>
      </template>
      <template #item="{ item, attrs }" v-if="multiple">
        <div style="max-width:360px">
          <v-checkbox :input-value="attrs.inputValue"
                      color="primary"
                      class="pt-2 pb-2 mt-0"
                      :hide-details="true"
                      @change="updateTotal($event)"
          >
            <template v-slot:label>
              <read-more :classes="'mb-0'"
                         :text="item.name"
                         :max-chars="80"
                         :font-size="14"
              />
            </template>
          </v-checkbox>
        </div>
      </template>
      <template #prepend-item v-if="multiple && btnAll">
        <div class="pa-2">
          <v-checkbox class="ma-0 pa-0 mx-2" @click="selectAllAction()" hide-details :color="selectAll === 'undefined' ? 'neuter' : 'primary'" v-model="selectAll" :indeterminate="selectAll === 'undefined'">
            <template #label>
              {{ selectAll ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
            </template>
          </v-checkbox>
        </div>
        <v-divider />
        </template>
      <template #append-item>
        <v-divider class="my-2" />
        <v-layout justify-space-between d-flex align-content-center align-center>
          <!--<v-chip outlined class="mx-4 py-4 pagination__general"> {{ $t('general.titles.general') }}: {{ totalGeneral }} </v-chip>-->
          <v-spacer />
          <div class="pagination mx-1">
            <v-btn text small :disabled="isFirstPage" @click="previousPage">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <span style="font-size: 12px" class="mx-2 ma-0 pa-0">{{ firstElementIndex + 1 }} {{ $t('general.table.pageText') }} {{ lastElementIndex }} - {{ totalFiltered }}</span>
            <v-btn text small :disabled="isLastPage" @click="nextPage">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-layout>
      </template>
    </v-autocomplete>
    <span v-if="showSelected && selected.length > 0">
      <v-sheet
          elevation="10"
          class="py-4 px-1 elevation-0 mb-4 d-flex justify-center"
          outlined
      >
        <v-chip small>{{ $t('general.titles.totalSelected') }}: {{ total }}</v-chip>
    </v-sheet>
    </span>
  </div>
</template>


<script>
import _ from "lodash";
import {mapActions} from "vuex";
import ReadMore from "@/components/ReadMoreComponent.vue";
import OrderListService from "@/services/OrderListService";
import LanguageService from "@/services/LanguajeService";
import StructuresService from "@/services/StructuresService";

export default {
  name: 'ASelectLots',
  components: {ReadMore},
  props: {
    flat: {
      type: Boolean,
      required: false,
      default: false
    },
    background: {
      type: String,
      required: false,
      default: null
    },
    fieldName: {
      type: String,
      required: true
    },
    showSelected: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    solo: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: true
    },
    btnAll: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      search: '',
      items: [],
      selected: [],
      itemsPerPage: [5, 10, 20],
      totalPages: 0,
      totalSelected: 0,
      total: 0,
      totalGeneral: 0,
      totalFiltered: 0,
      pageableDTO: {
        page:0,
        size:10,
        sortBy: "id",
        direction: "desc"
      },
      selectedItems: [],
      loading: false,
      selectAll: 'undefined',
    };
  },
  async created() {
    this.loading = true

    await this.fetchItems()
    .then(() => {
      if (this.selected.length === this.total && this.total !== 0)
        this.selectAll = true
      else if (this.selected.length > 0)
        this.selectAll = 'undefined'
      else if (this.selected.length === 0) this.selectAll = false

      this.$emit('data:change', this.selected)

      this.total = this.selected.length
    })
    .finally(() => {
      this.loading = false
    });
  },

  mounted() {
    this.$root.$on("clear:selected:lots", () => {
      this.selected = []
    })
  },

  computed: {
    isFirstPage() {
      return this.pageableDTO.page === 0;
    },
    isLastPage() {
      return this.pageableDTO.page >= this.totalPages - 1;
    },
    firstElementIndex() {
      return this.pageableDTO.page * this.pageableDTO.size;
    },
    lastElementIndex() {
      return Math.min((this.pageableDTO.page + 1) * this.pageableDTO.size, this.totalFiltered);
    },
    byPage() {
      const items = this.items.map(item => item.id);
      const selected = this.selected.map(item => item.id);

      const commonItems = items.filter(item => selected.includes(item));

      return commonItems.length;
    }
  },

  methods: {
    ...mapActions({
      fetchListStructureLots: 'details/fetchListStructureLots',
    }),
    async clearInput() {
      this.$emit('data:change', []);

      this.search = '';
      this.pageableDTO.page = 0;
      await this.fetchItems();
    },
    async selectAllAction() {
      await this.fetchItems();
    },
    previousPage() {
      if (!this.isFirstPage) {
        this.pageableDTO.page--;
        this.fetchItems();
      }
    },
    nextPage() {
      if (!this.isLastPage) {
        this.pageableDTO.page++;
        this.fetchItems();
      }
    },
    updateTotal(e) {
      if (e) this.total++;
      else this.total--;

      setTimeout(() => {
        if (this.selected.length === 0) {
          this.selectAll = false;
          this.total = 0;
        } else if (this.selected.length > 0) {
          this.selectAll = 'undefined';
          this.total = this.selected.length;
        } else if (this.selected.length === this.total) {
          this.selectAll = true;
        }
      }, 1000);
    },
    async fetchItems() {
      const search = _.clone(this.search);

      const filters = {
        stringFilter: this.search || null,
        pageableDTO: this.pageableDTO
      };

      const { data } = await StructuresService.structuresLot(filters);

      this.items = _.map(data.content, (r) => {
        return {
          ...r,
          name: this.translate(r.name)
        };
      });

      this.items.sort((a, b) => OrderListService.customSort(a.name, b.name));

      this.totalFiltered = data.totalElements;
      this.totalGeneral = data.totalGeneral;
      this.totalSelected = data.totalSelected;
      this.totalPages = data.totalPages;

      // Asegúrate de emitir un evento para actualizar los datos en el componente
      this.$emit('data:change', this.selected);
      this.$emit('update-pagination', this.pageableDTO);

      setTimeout(() => {
        this.search = search;
      }, 0);
    },

    translate(key, uppercase) {
      return LanguageService.getKey3(key);
    },
    async updateSearchInput(search) {
      if (typeof search === 'object') {
        this.search = search.target.value;
      }

      // Restablecer la página a 0 cada vez que se realiza una búsqueda
      this.pageableDTO.page = 0;
      this.pageableDTO.size = 10;
      this.pageableDTO.sortBy = "id";
      this.pageableDTO.direction = "desc";

      this.loading = true;
      await setTimeout(async () => {
        await this.fetchItems().finally(() => {
          this.loading = false;
        });
      }, 2000);
    },
  },

  destroyed() {
    this.$root.$off('clear:selected:lots');
  }
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.v-data-footer.pagination {
  border-radius: .4em;
  border: 1px solid #e0e0e0 !important;
}

.v-data-footer.pagination :deep(.v-data-footer__select) {
  display: none !important;
}

.pagination__general {
  border-radius: .4em;
}
</style>
