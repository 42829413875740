import * as types from "./mutations-type"
import LanguajeService from "../../../services/LanguajeService"
import i18n from "@/plugins/i18n"
import MapService from "../../../services/MapService"
import AuthService from "../../../services/AuthService"

export default {
  loadingON({ commit, state }) {
    commit(types.SET_LOADING_ON, true)
  },
  loadingOFF({ commit }) {
    commit(types.SET_LOADING_OFF, false)
  },
  setLoadingMessage({ commit }, message) {
    commit("SET_LOADING_MESSAGE", message)
  },
  setLoadingIcon({ commit }, icon) {
    commit("SET_LOADING_ICON", icon)
  },
  setLayout({ commit }, layout) {
    commit(types.SET_LAYOUT, layout)
  },
  async getLanguajeKeys({ commit }, [toast]) {
    await LanguajeService.languajeKeys()
      .then((resp) => {
        commit(types.GET_LANGUAJE, resp.data)
      })
      .catch(() => {
        toast.error(i18n.t("general.notification.languajeError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
      })
  },
  async getLanguageStructure({ commit }, [toast]) {
    await LanguajeService.languageStructure()
      .then((resp) => {
        commit(types.LANGUAJE_STRUCTURE, resp.data)
      })
      .catch(() => {
        toast.error(i18n.t("general.notification.languajeError"), {
          icon: "mdi-alert-circle",
          queueable: true,
        })
      })
  },
  async fetchListCountry({ commit }) {
    try {
      const { data } = await MapService.countryList()

      for (let key in data) {
        if (key.startsWith("us-")) {
          delete data[key]
        }
      }

      commit(types.SET_COUNTRY_LIST, data)
    } catch (error) {
      console.error(error)
    }
  },
  async changePass({ dispatch, commit }, [dataChange, toast]) {
    dispatch("loadingON")
    try {
      const { data } = await AuthService.changePass(dataChange)
      if (!!data) {
        toast.success(i18n.t("general.notification.changePass"), {
          icon: "mdi-check-circle", queueable: true,
        })
      }
      commit(types.CHANGED, !!data)
      dispatch("loadingOFF")
    } catch (error) {
      if (error.message !== "cancelRequest")
        toast.error(i18n.t("general.notification.changePassError"), {
          icon: 'mdi-check-circle',
          queueable: true,
        })
    }
  },
  async getInitData({ dispatch, commit }, { toast, reload }) {
    const profile = JSON.parse(localStorage.getItem("profile"));

    try {
      // Configurar las integraciones de la compañía
      commit("integration/SET_INTEGRATIONS", profile.integrationsCompany, { root: true });

      await dispatch("loadingON");

      await dispatch("customization/LOAD_CONFIGURATION", null, { root: true });
      console.log("Init Data");

      const steps = [];

      // Cargar diferentes pasos según la lógica de negocio para VOITER
      if (profile.company_owner === "VOITER") {
        steps.push({
          message: i18n.t("general.loadingMessages.fetchContractsNames"),
          action: () => dispatch("reports/fetchContractsNames", [profile, toast], { root: true }),
          icon: "mdi-file-document-multiple",
        });
        steps.push({
          message: i18n.t("general.loadingMessages.fetchSellersNames"),
          action: () => dispatch("reports/fetchSellersNames", [profile, toast], { root: true }),
          icon: "mdi-account-hard-hat",
        });
        steps.push({
          message: i18n.t("general.loadingMessages.fetchVolumesN"),
          action: () => dispatch("reports/fetchVolumesN", [profile, toast], { root: true }),
          icon: "mdi-chart-donut-variant",
        });
        steps.push({
          message: i18n.t("general.loadingMessages.fetchVolumesB"),
          action: () => dispatch("reports/fetchVolumesB", [profile, toast], { root: true }),
          icon: "mdi-barrel",
        });
        steps.push({
          message: i18n.t("general.loadingMessages.fetchQuebra"),
          action: () => dispatch("reports/fetchQuebra", [profile, toast], { root: true }),
          icon: "mdi-chart-bell-curve-cumulative",
        });
      }

      // Resto de pasos a ejecutar
      steps.push({
        message: i18n.t("general.loadingMessages.fetchRangeScore"),
        action: () => dispatch("inspections/fetchRangeScore", [profile.company_id], { root: true }),
        icon: "mdi-order-bool-ascending-variant",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.getLanguageStructure"),
        action: () => dispatch("getLanguageStructure", [toast]),
        icon: "mdi-earth",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchListProccess"),
        action: () => dispatch("proccess/fetchListProccess", [profile, toast], { root: true }),
        icon: "mdi-all-inclusive",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchListAuthorityProcess"),
        action: () => dispatch("authority/fetchListAuthorityProcess", [profile.id, toast], { root: true }),
        icon: "mdi-account-key",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchStorageTypesActiveByCompany"),
        action: () => dispatch("storage_type/fetchStorageTypesActiveByCompany", [profile, toast], { root: true }),
        icon: "mdi-database-plus",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchAllStorageTypesByCompany"),
        action: () => dispatch("storage_type/fetchAllStorageTypesByCompany", [profile.company_id, toast], { root: true }),
        icon: "mdi-database",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchListDocumentTypesByCompany"),
        action: () => dispatch("document_type/fetchListDocumentTypesByCompany", [profile.company_id, toast], { root: true }),
        icon: "mdi-file-document",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.getAuthoritiesStorageDevices"),
        action: () => dispatch("authority/getAuthoritiesStorageDevices", [{
          userId: profile.id,
          companyId: profile.company_id,
          isActive: true,
        }, toast], { root: true }),
        icon: "mdi-warehouse",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.getLocationsByAuthority"),
        action: () => dispatch("authority/getLocationsByAuthority", [{
          userId: profile.id,
          companyId: profile.company_id,
          isActive: true,
        }, toast], { root: true }),
        icon: "mdi-map-marker",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchListDocuments"),
        action: () => dispatch("documents/fetchListDocuments", [profile, toast], { root: true }),
        icon: "mdi-file-multiple",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchGroupStructuresDefault"),
        action: () => dispatch("groupDetails/fetchGroupStructuresDefault", [toast], { root: true }),
        icon: "mdi-text-box-outline",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchGroupStructuresByCompany"),
        action: () => dispatch("groupDetails/fetchGroupStructuresByCompany", [profile, toast], { root: true }),
        icon: "mdi-briefcase",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchListStructures"),
        action: () => dispatch("records/fetchListStructures", [profile, toast], { root: true }),
        icon: "mdi-text-box-multiple",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchListAuthority"),
        action: () => dispatch("authority/fetchListAuthority", [profile, toast], { root: true }),
        icon: "mdi-account-tie",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchNotificationsMenuByUser"),
        action: () => dispatch("notifications/fetchNotificationsMenuByUser", [toast], { root: true }),
        icon: "mdi-bell",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.loadConfigUser"),
        action: () => dispatch("authority/loadConfigUser", [toast], { root: true }),
        icon: "mdi-cog",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchClassificationTypesStorage"),
        action: () => dispatch("storage/fetchClassificationTypes", null, { root: true }),
        icon: "mdi-shape-outline",
      });
      steps.push({
        message: i18n.t("general.loadingMessages.fetchClassificationTypesLocations"),
        action: () => dispatch("locations/fetchClassificationTypes", null, { root: true }),
        icon: "mdi-map-marker-radius",
      });

      // Obtener el array de mensajes
      const messages = steps.map(step => step.message);
      const icons = steps.map(step => step.icon);

      // Cambiar los mensajes de carga y sus íconos cada 2 segundos
      let messageIndex = 0;
      const messageInterval = setInterval(() => {
        dispatch("setLoadingMessage", messages[messageIndex]);
        dispatch("setLoadingIcon", icons[messageIndex]);  // Actualización del ícono
        messageIndex = (messageIndex + 1) % messages.length;
      }, 2000);

      // Ejecutar las promesas en paralelo sin esperar a que una termine
      await Promise.allSettled(
        steps.map(step => step.action())
      );

      // Limpiar el mensaje y detener el intervalo
      clearInterval(messageInterval);
      await dispatch("setLoadingMessage", "");
      await dispatch("setLoadingIcon", "");  // Limpiar el ícono

      if (!reload) {
        commit(types.GET_INIT_DATA);
      }

      await dispatch("loadingOFF");
    } catch (e) {
      console.error(e);
    }
  }
}
