import * as types from './mutations-type.js';

export default {
    [types.SET_LOADING_ON](state, data) {
        state.loading = data;
    },
    [types.SET_LOADING_OFF](state, data) {
        state.loading = data;
    },
    [types.SET_HEADERS](state, data) {
        state.headers = data;
    },
    [types.SET_DIALOG_DETAILS](state, data) {
        state.dialogDetails = data;
    },
    [types.SET_APPOINTMENTS_BY_COMPANY_PAGINATED](state, data) {
        state.recordsByCompany = data.content;
        state.recordsByCompanyTotalCount = data.totalElements;
    },
    [types.SET_APPOINTMENTS_BY_COMPANY](state, data) {
        state.recordsByCompany[data.sdt] = data.content;
    },
    [types.SET_APPOINTMENTS_BY_COMPANY_FILTERED](state, data) {
        state.recordsByCompanyFiltered = data;
    },
    [types.SET_CANT_TO_EXPORT](state, data) {
        state.cantToExport = data.number_of_elements_to_show;
    },
    [types.SET_APPOINTMENTS_BY_PROCESS](state, data) {
        state.recordsToExports = data;
    },
    [types.SET_APPOINTMENTS_BY_LOTE](state, data) {
        state.recordsToTraceabilityReport = data;
    },
    [types.SET_TAB_INDEX](state, data) {
        state.tabIndex = data.data;
    },
    [types.SET_STRUCTURES_LIST](state, data) {
        state.structures = data;
    },
    [types.SET_RECORD](state, data) {
        state.record = data;
    },
    [types.SET_EVIDENCES](state, data) {
        state.evidences = data;
    },
    [types.RESTORE_EVIDENCES](state) {
        state.evidences = [];
    },
    [types.SET_RECORDS_FOR_PRINT](state, data) {
        state.recordsForPrint = data;
    },
    [types.SET_SOURCE](state, data) {
        state.source = data;
    },
    [types.SET_REQUEST](state, data) {
        state.request = data;
    },

    [types.SET_ORIGINAL_STATE_RECORD](state) {
        state.record = {
            company: null,
            process: null,
            person_in_charge: null,
            originApp: null,
            sdi_origin: null,
            sdi_destination: null,
            record_date_time: new Date(),
            recordStructureValuesOrganized: {},
            evidences: {},
        }
    },

    [types.SET_ORIGINAL_STATE_FOR_PRINT_RECORDS](state) {
        state.recordsForPrint = [];
    },

    [types.EXPORT](state, data) {
        state.exportUrl = data;
    },

    [types.SET_APPOINTMENTS_BY_COMPANY_PAGINATED_WITHOUT_STAGE](state, data) {
        state.recordsByCompanyWithoutStage = data.content;
        state.recordsByCompanyTotalCountWithoutStage = data.totalElements;
    },
    [types.SET_RECORD_LOG](state, data) {
        state.recordLog = data;
    },
    [types.RESTORE_RECORD_LOG](state, data) {
        state.recordLog = {
            recordById: null,
            actionExecute: '',
            insertionDate: '',
            blockchainHash: null,
            url: '',
            blockchainSave: false
        };
    },
    [types.RESTORE_RECORD](state) {
        state.record = {
            company: null,
            process: null,
            person_in_charge: null,
            originApp: null,
            sdi_origin: null,
            sdi_destination: null,
            record_date_time: new Date().toJSON(),
            sent_date_time: new Date().toJSON(),
            recordStructureValuesOrganized: {},
            evidences: {},
        };
    },
    [types.CHECK_BURDENS](state, data) {
        state.checkBurdensError = data;
    },
    [types.CHECK_BURDENS_DATA](state, data) {
        state.checkBurdensErrorData = data;
    }
}
