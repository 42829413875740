<template>
    <div class="locale-changer">
        <v-select id="locale-changer"
                  :items="langs"
                  v-model="locale"
                  max-height="auto"
                  return-object
                  text
                  :dense="dense"
                  solo
                  color="#cccccc"
                  :flat="flat"
                  :background-color="background"
                  :dark="dark"
                  item-color="primary"
                  :hide-details="true"
                  @change="languageChange($event)"
                  append-icon="mdi-chevron-down"
        >
            <template slot="selection" slot-scope="data">
                <div id="languajeSelected">
                    <v-avatar tile :height="flagH" :width="flagW" :min-width="flagW">
                        <v-img :src="require('@/assets/flag/' + data.item + '.png')"></v-img>
                    </v-avatar>
                </div>
            </template>
            <template slot="item" slot-scope="data">
                <template>
                    <div class="languajeName">
                        <v-avatar tile :height="flagH" :width="flagW" :min-width="flagW">
                            <v-img :src="require('@/assets/flag/' + data.item + '.png')"></v-img>
                        </v-avatar>
                        {{data.item.split('-')[1].toUpperCase()}}
                    </div>
                </template>
            </template>
        </v-select>
    </div>
</template>


<script>
    export default {
        name: 'locale-changer',
        props: [
            'dense',
            'flagW',
            'flagH',
            'flat',
            'background',
            'dark'
        ],
        data() {
            return {
                langs: [],
                locale: this.$i18n.locale
            }
        },
        watch: {},
        created() {
            this.langs = this.$i18n.availableLocales;
        },
        methods: {
            languageChange(lang) {
                this.$i18n.locale = lang;
                localStorage.setItem('locale', lang);
                location.reload();
            }
        }
    }
</script>

<style lang="scss" scoped>
    :deep(i.v-icon) {
      color: var(--v-t-bw-secondary-base)
    }
</style>
